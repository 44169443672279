import React, { Component } from 'react'
import Home from './components/Home/Home'
import AddContest from './components/Contest/AddContest'
import ViewContest from './components/Contest/ViewContest'
import EditContest from './components/Contest/EditContest'
import TranslateQuestions from './components/Quiz/TranslateQuestions'
import Leaderboard from './components/Leaderboard/Leaderboard'
import Login from './components/Login/Login'
import AuthRoutes from './utils/AuthRoutes'
import {connect} from 'react-redux'
import {BrowserRouter, Route} from 'react-router-dom'
import {getQuestions, getDomain, getLanguages} from './redux/actions'

class App extends Component {

  componentDidMount(){
    this.props.getQuestions()
    this.props.getDomain()
    this.props.getLanguages()
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <AuthRoutes path="/" exact component={Home} />
          <Route path="/login" component={Login} />
          <AuthRoutes path="/contest" exact component={AddContest} />
          <AuthRoutes path="/translate" exact component={TranslateQuestions} />
          <AuthRoutes path="/view-contest" exact component={ViewContest} />
          <AuthRoutes path="/edit-contest" exact component={EditContest} />
          <AuthRoutes path="/leaderboard" exact component={Leaderboard} />
        </BrowserRouter>
      </div>
    );
  }
}

const mapDispatchtoProps = dispatch => {
  return{
    getQuestions: () => {dispatch(getQuestions())},
    getLanguages: () => {dispatch(getLanguages())},
    getDomain: () => {dispatch(getDomain())}
  }
}

export default connect(null, mapDispatchtoProps)(App);
