import React from 'react'
import Spinner from 'react-spinkit'

const Loader = (props) => {
    const style = {
        position: 'absolute',
        width: '100%',
        height: '100%',
    }
    return(
        <div style={style} className="d-flex justify-content-center align-items-center">
            <Spinner name="wordpress" color="teal"/>
        </div>
    )
}

export default Loader;