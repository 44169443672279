import { toast } from 'react-toastify';
const initState = {
    domain: [],
    lang: [],
    questions: [],
    current_contest: {},
    alert_options: {
        position: toast.POSITION.TOP_CENTER
    },
    loggedIn: false,
    emailid: '',
}

const reducer = (state = initState, action) => {
    if(action.type === 'GET_QUESTIONS'){
        return{
            ...state,
            questions: action.payload
        }
    }
    if(action.type === 'GET_LANGUAGES'){
        return{
            ...state,
            lang: action.payload.sort()
        }
    }
    if(action.type === 'GET_DOMAIN_NAMES'){
        return{
            ...state,
            domain: action.payload.sort((a, b) => {
                if(a.length > b.length) return 1
                else if(a > b) return 1
                else return -1
            })
        }
    }
    if(action.type === 'GET_SELECTED_CONTEST'){
        return{
            ...state,
            current_contest: action.payload
        }
    }
    if(action.type === 'LOGIN_USER'){
        sessionStorage.setItem('token', action.payload.accessToken)
        return{
            ...state,
            emailid: action.payload.email,
            loggedIn: true
        }
    }
    if(action.type === 'LOGOUT_USER'){
        sessionStorage.removeItem('token')
        return{
            ...state,
            emailid: '',
            loggedIn: false
        }
    }
    return state;
}

export default reducer;