import React from 'react'
import DatePicker from 'react-date-picker';
import Axios from 'axios'
import {Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import Loader from '../../utils/Loader';
import {BASE_API_URL} from './../../utils/constants'

class LeaderBoard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(Date.now() + (60*24*60*60*1000)),
            domain: '',
            size: 0,
            selected_contest: '',
            contest: [],
            data: []
        }
    }

    DateFormat = (date) => {
        let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let day;
        date = new Date(date);
        if(date.getDate() === 1) day = date.getDate() + 'st';
        else if(date.getDate() === 2) day = date.getDate() + 'nd';
        else if(date.getDate() === 3) day = date.getDate() + 'rd';
        else day = date.getDate() + 'th';
        let p1 =  day + ' ' + month[date.getMonth()] + ' ' + date.getFullYear()
        return p1
    }

    onSelectChange = event => this.setState({[event.target.id] : event.target.value})
    onStartDateChange = date => this.setState({ startDate: date })
    onEndDateChange = date => this.setState({ endDate: date })
    getContests = event => {
        this.setState({domain : event.target.value})
        Axios.get(`${BASE_API_URL}/contest/getAllContests?domain=${event.target.value}`)
        .then((res) => {
            this.setState({contest: res.data})
        })
        .catch((e) => { this.setState({contest: []}) })
    }
    onSubmitHandler = event => {
        event.preventDefault();
        this.setState({data: []})
        if(this.state.startDate !== '' && this.state.endDate !== '' && this.state.domain !== '' && this.state.size !== 0 && this.state.selected_contest !== ''){
            Axios.get(`${BASE_API_URL}/leaderboard/forAdmin`, {
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                domain: this.state.domain,
                contest_id: parseInt(this.state.selected_contest),
                size: this.state.size
            }
            })
            .then((res) => {
                let response = res.data;
                if(res.data.length !== 0){
                    let data = response.map((item) => {
                        return{
                            index: response.indexOf(item) + 1,
                            name: item.name,
                            uid: item.uid,
                            date: this.DateFormat(item.played_at),
                            score: item.score,
                            contest: item.prize_name
                        }
                    })
                    this.setState({data: data})    
                }
                else toast.error('No Data Available', this.props.options)
            })
            .catch((e) =>{
                console.log(e)
                toast.error('Server Error', this.props.options)
            })
        }
        else{
            toast.error('All fields are required', this.props.options)
        }
    }

    render(){
        let {data} = this.state
        if(this.props.domainList.length === 0){
            return(
                <Loader />
            )
        }
        let {domainList} = this.props
        return(
            <div>
                <div>
                <ToastContainer autoClose={1500} style={{textAlign: 'center'}} />
                </div>
            <div className="container mt-4 mb-4" style={{paddingLeft: '10%', paddingRight: '10%'}} >
                <Link to="/">
                <button className="btn btn-secondary p-1 font-weight-bold"> <span><i className="home icon"></i></span>Home</button>
                </Link>
                <div className="card mb-2 mt-1" style={{paddingLeft: '2%', paddingRight: '2%', backgroundColor: 'whitesmoke'}} >
                <h1 className="display-5 text-center">Leaderboard</h1>                              
                    <div className="form-row mb-1">
                        <div className="col md-6 sm-12">
                        
                        <label htmlFor="start date">Start Date</label>
                        <br />
                        <DatePicker
                            onChange={this.onStartDateChange}
                            value={this.state.startDate}
                            className="form-control"
                        />                   
                        
                        </div>
                        
                        <div className="col md-4 sm-12">
                        <label htmlFor="end date">End Date</label>
                        <br />
                        <DatePicker
                            onChange={this.onEndDateChange}
                            value={this.state.endDate}
                            className="form-control"	
                        />
                        </div>
                    </div>

                    <div className="form-row mt-1">

                        <div className="form-group col-md-6 sm-12">
                        
                        <label htmlFor="domain">Domain</label>
                        <select id="domain" className="form-control" value={this.state.domain.length !== 0 ? this.state.domain : "DEFAULT"} onChange={this.getContests}>
                            <option disabled value="DEFAULT">Select a domain</option>
                            {domainList.map(item => 
                                    <option key={domainList.indexOf(item)} value={item.name}>{item.name.toUpperCase()}</option>    
                            )}
                        </select>
                        
                        </div>

                        <div className="form-group col-md-6 sm-12">
                        
                        <label htmlFor="size">Size</label>
                        <select id="size" className="form-control" value={this.state.size !== 0 ? this.state.size : "DEFAULT"} onChange={this.onSelectChange}>
                            <option disabled value="DEFAULT">No. of results to view</option>
                            <option>10</option><option>20</option><option>30</option>
                            <option>40</option><option>50</option><option>100</option><option>500</option>
                        </select>
                        
                        </div>

                       
                        {this.state.contest.length !== 0 ?
                            <div className="form-group col-md-12 sm-12">
                                <label htmlFor="selected_contest">Contests</label>
                                <select id="selected_contest" className="form-control" value={this.state.selected_contest.length !== 0 ? this.state.selected_contest : "DEFAULT"} onChange={this.onSelectChange}>
                                <option disabled value="DEFAULT">Select a contest</option>
                                {this.state.contest.map((item, index) => {
                                    return(
                                        <option key={index} value={item.contest_id}>{`${item.prize_name} ${item.isDeleted === 1 ? ' (Expired)' : ''}`}</option>
                                    )
                                })}
                                </select>
                            </div>
                            : null
                        }

                    </div>
                    <button type="button" onClick={this.onSubmitHandler} className="btn btn-lg btn-info p-1 mb-2" style={{width: '100%'}}>Submit</button>
                </div>
                {
                    (data.length !== 0)
                    ?
                    (
                        <table className="table table-striped table-responsive-sm">
                        <thead>
                            <tr key="default">
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">UID</th>
                            <th scope="col">Played On</th>
                            <th scope="col">Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => {
                                return(
                                    <tr key={item.index}>
                                    <th scope="row">{item.index}</th>
                                    <td>{item.name}</td>
                                    <td>{item.uid}</td>
                                    <td>{item.date}</td>
                                    <td><strong>{item.score}</strong></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        </table>
                    )
                    :
                    null
                }
            </div>
            </div>
        )
    }
}

const mapStatetoProps = state => {
    return{
        domainList: state.domain,
        options: state.alert_options,
    }
}

export default connect(mapStatetoProps, null)(LeaderBoard);