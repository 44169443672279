import React from 'react';
import Axios from 'axios';
import {Link} from 'react-router-dom';
import localeEmoji from 'locale-emoji';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect} from 'react-redux'
import Loader from './../../utils/Loader';
import {BASE_API_URL} from './../../utils/constants'

class TranslateQuestions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            translation: '',
            curr_question: '',
            lang: ''
        }
    }

    onSelectChange = event => {
        this.setState({curr_question: event.target.value})
    }

    onLanguageSelect = event => {
        this.setState({lang: event.target.value})
    }

    onChange = event => {
        this.setState({translation: event.target.value})
    }

    onCopy = () => {
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute('value', this.state.curr_question);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }

    onSubmitHandler = event => {
        event.preventDefault();
        let {translation, lang, curr_question} = this.state
        let {options} = this.props
        if(translation.length && curr_question.length && lang.length){
            Axios.post(`${BASE_API_URL}/quiz/translation`, {
                translation: translation.trim(),
                question: curr_question,
                language: lang
            }).then(res => {
                if(res.status === 200){
                    toast.success('Translation Added Successfully', options) 
                    this.setState({translation: ''})          
                }
            }).catch((e) => {
                toast.error('Server Error. Translation Not Added', options)
            });
        }
        else toast.error('All fields are required', options)
    }

    render(){
        let {questions, language} = this.props
        let { translation, lang } = this.state;
        if(questions.length === 0 && language.length === 0){
            return(
                <Loader />
            )
        }
        else{
            return(
                <div>            
                <ToastContainer autoClose={1500} style={{textAlign: 'center'}} />
                <div className="container mt-4 mb-4" style={{paddingLeft: '10%', paddingRight: '10%', paddingTop: '5%'}} >
                    <Link to="/">
                    <button className="btn btn-secondary p-1 font-weight-bold"> <span><i className="home icon"></i></span>Home</button>
                    </Link>
                <div className="card mb-2 mt-1 pt-3" style={{paddingLeft: '2%', paddingRight: '2%', backgroundColor: 'whitesmoke'}} >
                
                <h1 className="display-4 text-center"> Translate Question  {(lang !== '') ? localeEmoji(lang) : null} </h1>
                    
                <form action="/translate" method="POST" onSubmit={this.onSubmitHandler}>
                    
                    <div className="form-row mb-2">
    
                        <div className="col md-10 sm-12">
                            <label htmlFor="question"> Select a Question </label>
                            <select id="question" className="form-control" defaultValue="DEFAULT" onChange={this.onSelectChange}>
                                <option key="none" disabled value="DEFAULT">Select a question</option>
                                {questions.map(item => 
                                    <option key={questions.indexOf(item)} value={item.questions}>{item.questions}</option>    
                                )}
                            </select>
                            <div>
                            <button type="button" onClick={this.onCopy} className="btn p-1 mt-2" style={{backgroundColor: '#555', color: 'white'}}>Copy Question<span><i className="copy icon"></i></span></button>
                            </div>                  
                        </div>
    
                    </div>
    
                    <div className="form-row mt-1">
    
                    <div className="form-group col-md-12 sm-12">
                        <label htmlFor="language">Language to translate</label>
                        <select id="language" className="form-control" defaultValue="DEFAULT" onChange={this.onLanguageSelect}>
                            <option key="none" disabled value="DEFAULT">Select a language</option>
                            {language.map(item => 
                                    <option key={language.indexOf(item)} value={item.lang}>{item.lang.toUpperCase()}</option>    
                            )}
                        </select>
                    </div>
    
                    </div>
                    
                    <div className="form-group">
                        
                        <label htmlFor="name">Enter Transalation</label>
                        <input type="text" className="form-control" id="translation" placeholder="Enter translation" onChange={this.onChange} value={translation} required/>
                    
                    </div>            
            
                    <button className="btn btn-lg btn-info p-1 mb-2" style={{width: '100%'}}>Submit</button>
    
                </form>
    
                </div>
            </div>
            </div>);
        }
    }
}

const mapStatetoProps = state => {
    return{
        questions: state.questions,
        language: state.lang,
        options: state.alert_options,
    }
}

export default connect(mapStatetoProps, null)(TranslateQuestions);