export let BASE_API_URL = '';
export const CLIENT_ID = '1088435618557-bgp7tagm1ngaskg3b6mp0ga8h0432eq3.apps.googleusercontent.com'

 if (window.location.hostname.includes('localhost')){
     BASE_API_URL = 'http://localhost:4000/api';
 }
 else {
    BASE_API_URL = `${window.location.origin}/api`
}
 
