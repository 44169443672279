import React from 'react'
import Axios from 'axios'
import {Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect} from 'react-redux'
import {SelectedContest} from './../../redux/actions'
import {BASE_API_URL} from './../../utils/constants'

class ViewContest extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            domain: '',
            data: [],
            prop_data: []
        }
    }


    DateFormat = (date) => {
        let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let day;
        date = new Date(date);
        if(date.getDate() === 1) day = date.getDate() + 'st';
        else if(date.getDate() === 2) day = date.getDate() + 'nd';
        else if(date.getDate() === 3) day = date.getDate() + 'rd';
        else day = date.getDate() + 'th';
        let p1 =  day + ' ' + month[date.getMonth()] + ',' + date.getFullYear()
        return p1
    }

    onSubmitHandler = () => {
        let {domain} = this.state
        if(domain){
            Axios.get(`${BASE_API_URL}/contest/getContests?domain=${domain}`)
            .then((res) => {
                    let response = res.data
                    this.setState({
                        prop_data: response
                    })
                    response = response.map((item) => {
                        return{
                            index: response.indexOf(item) + 1,
                            dc_id: item.id,
                            contest_id: item.contest_id,
                            prize_name: item.prize_name,
                            endDate: item.end_date,
                            start_date: this.DateFormat(item.start_date),
                            end_date: this.DateFormat(item.end_date),
                            isDeleted: item.isDeleted,
                        }
                    })
                    this.setState({data: response})
            }).catch((e) => toast.error('Server Error/No Data Available', this.props.option))
        }
        else toast.error('Select a domain first', this.props.option)
        
    }

    onSelectChange = event => {
        this.setState({
            domain : event.target.value
        })
    }

    onUpdate = (event) => {
        let index = event.target.id - 1;
        let {prop_data} = this.state;
        this.props.onUpdate(prop_data[index]);
        this.props.history.push("/edit-contest");
    }    

    onDelete = (event) => {
        let dc_id = parseInt(event.target.id)
        let index = parseInt(event.target.name) - 1
        let newData = [...this.state.data]
        newData[index].isDeleted = 1

        Axios.post(`${BASE_API_URL}/contest/deleteContest?id=${dc_id}`)
        .then((res) => {
            if(res.status === 200){
                this.setState({
                    data: newData
                })      
                toast.success('Deleted Successfully', this.props.option)  
            }
        }).catch( e => toast.error('Server Error', this.props.option))
    }

    render(){
        let {data} = this.state
        let {domainList} = this.props
        return(
        <div>
            <div>
            <ToastContainer autoClose={1500} style={{textAlign: 'center'}} />
            </div>
        <div className="container mt-4 mb-4" style={{paddingLeft: '10%', paddingRight: '10%', paddingTop: '5%'}} >
            <Link to="/">
            <button className="btn btn-secondary p-1 font-weight-bold"> <span><i className="home icon"></i></span>Home</button>
            </Link>
            <div className="card mb-2 mt-1 pt-3" style={{paddingLeft: '2%', paddingRight: '2%', backgroundColor: 'whitesmoke'}} >
            <h1 className="display-4 text-center"> View Contest </h1>
                
            <div className="form-row mt-1">
                <div className="form-group col-md-12 sm-12">
                    <label htmlFor="domain">Domain </label>
                    <select id="domain" className="form-control" value={this.state.domain ? this.state.domain : "DEFAULT"} onChange={this.onSelectChange}>
                    <option disabled value="DEFAULT">Select a domain</option>
                    {domainList.map(item => 
                                    <option key={domainList.indexOf(item)} value={item.name}>{item.name.toUpperCase()}</option>    
                    )}  
                    </select>
                </div>
            </div>

            {
                    (data.length !== 0)
                    ?
                    (
                        <table className="table table-striped table-responsive-sm">
                        <thead>
                            <tr key="default">
                            <th scope="col">#</th>
                            <th scope="col">Contest</th>    
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => {
                                return(
                                    <tr key={item.index}>
                                    <th scope="row">{item.index}</th>
                                    <td>{item.prize_name}</td>
                                    <td>{item.start_date}</td>
                                    <td>{item.end_date}</td>
                                    <td><button className="btn btn-info p-0" onClick={this.onUpdate} id={item.index}>Edit</button></td>
                                    <td>{item.isDeleted === 0 ? <button className="btn btn-danger p-0" onClick={this.onDelete} id={item.dc_id} name={item.index}>Delete</button> : ''}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        </table>
                    )
                    :
                    null
                }
            
            <button className="btn btn-lg btn-info p-1 mb-2" style={{width: '100%'}} onClick={this.onSubmitHandler}>Submit</button>
            </div>
        </div>
        </div>
        )
    }
}

const mapStatetoProps = state => {
    return {
        domainList: state.domain,
        option: state.alert_options
    }
}

const mapDispatchtoProps = dispatch => {
    return{
        onUpdate: (data) => {dispatch(SelectedContest(data))}
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ViewContest)